.qrcode-height{
     min-height: calc(100% - 148px);
}

.slick-prev,
.slick-next {
  display: none !important;
}

.zomatoSlider .slick-dots li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #D8D8D8;
  opacity: 1;
  margin: 0 5px;
}

.zomatoSlider .slick-dots li.slick-active {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #707070;
}

.zomatoSlider .slick-dots li button:before {
  content: '' !important;
  color: #D8D8D8;
  opacity: 0;
}

.zomatoSlider .slick-dots li.slick-active button:before {
  content: '';
  opacity: 0;
}
