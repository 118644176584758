/**
Basic Custom Styles
 */
 .Mui-checked > .MuiFormControlLabel-root{
     /* Your styles here */
     background-color: blue !important; /* Example background color */
   }
   /* .MuiFormControlLabel-root:has(div.b){
     background-color: red !important;
   } */
.play-fair{
     font-family: 'Playfair Display'
}
 .map-custom{
     background-color: transparent !important;
     border: none !important;
 }
 .react-flag-button button{
     border: none;
 }
.business-type-dropdown,.business-type-dropdown-content,.business-type-dropdown form {
    overflow: unset !important;
}
.business-type-dropdown form label{
     z-index: 0;
}
.get-contact{
     overflow: unset;
}
.react-tel-input.scanner-phone .country-list{
     text-align: left;
}
.react-tel-input .country-list .country-name {
     color: #000;
 }
.qr-textarea textarea {
     color: black;
     line-height: 17px;
     font-size: 14px;
     font-weight: 500;
 }
 
.business-phone.react-tel-input .flag-dropdown.open {
     z-index: 999 !important;
 }
.rating-content-inActive{
     display: none;
}
.rating-content-active{
     display: block;
}
li.inline-block.rating-title-active{
     color: #0BA861;
     border-color: #0BA861;
}
li.inline-block.active-done{
     background-color: #0BA861 !important;
     color: #fff;
}
li.inline-block.rating-title-active.active-done{
     background-color: #222 !important;
     color: #fff;
     border-color: #222;
}

li.inline-block.active-done .m-icon{
     color: #fff;
}
.review-form fieldset{
     border: none;
}
.review-logo img{
     max-width: 150px;
}
.reactWordcloud svg{
     margin: 0 auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-height {
     min-height: calc(100vh - 300px);
     height: 100%;
}
.active-rating{
    box-shadow: 1px 0px 10px #00000029;
    border-radius: 100%;
    padding: 4px;
}
.inactive-rating{
     box-shadow: 1px 0px 10px transparent;
     border-radius: 100%;
     padding: 4px;
 }
.label-businessType {
     color: rgb(107, 114, 128);
     transform-origin: top left;
     max-width: calc(133% - 32px);
     position: absolute;
     left: 0;
     top: 0;
     transform: translate(14px, -9px) scale(0.75);
     z-index: 1;
     background-color: #fff;
     padding: 0 6px;
}
.ticker-view{
     justify-content: center;
     font-weight: 600;
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media(max-width: 767px){
     .review-logo img{
          max-width: 150px;
     }
     .xs-googleSearch{
          top: 55px !important;
     }
}
@media(max-width: 575px){
     .rating-ruler{
          max-width: 200px !important;
     }
}
@media(max-width: 991px){
     .get-contact{
          overflow-y: scroll;
     }
}
@media(max-width: 960px){
     .xs-border-dash td{
          border: none !important;
     }
}
@media (min-width: 768px) and (max-width: 991px){
     .review-logo img{
          max-width: 150px;
     }
     .review-logo{
          top: 30px !important;
     }
     .formmode.review-logo{
          top: 13px !important;
     }
}
@media (min-width: 992px) and (max-width: 1199px){
     .review-logo img{
          max-width: 150px;
     }
     .review-logo{
          top: 40px !important;
     }
     .formmode.review-logo{
          top: 15px !important;
     }
}
@media (min-width: 1200px) and (max-width: 1600px){
     .review-logo img{
          max-width: 150px;
     }
     .review-logo{
          top: 80px !important;
     }
     .formmode.review-logo{
          top: 40px !important;
     }
     .salse-form{
          width: 60% !important;
     }
}
/* laptop view */
@media(min-width: 1280px) and (max-width: 1599px){
.l-padding{
     padding: 20px !important;
}
.l-padding .gap-20 {
     gap: 20px;
 }
 .l-padding .l-width{
     width: 100%;
     margin-bottom: 20px;
 }
 .l-padding .l-block{
     display: block;
 }
 .l-padding .text-16{
     font-size: 18px;
 }
 .l-padding .mt-20{
     margin-top: 20px;
 }
}
/* laptop view End */
.pac-container {
     z-index: 2001 !important;
 }
 /* extra small mobile view */
 @media(max-width: 390){
     .extra-small-select{
          width: 80px;
     }
 }
 /* styles.css */
.loader-container {
     background-color: rgba(0, 107, 90, 0.2); 
     z-index: 9999;
}
   
.loader {
     animation: spin 2s linear infinite;
     color: #fff !important;
}

@keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
}
   
.StripeElement {
     display: block;
     margin: 10px 0 20px 0;
     padding: 14px 14px;
     font-size: 1em;
     font-family: "Source Code Pro", monospace;
     border: 1px solid #DBDBDB;
     outline: 0;
     border-radius: 4px;
     background: white;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6{
     width: 100px;
}

.select .muiltr-13cymwt-control{
     height: 50px;
}

.input_text_align .muiltr-154j6x2-MuiInputBase-input-MuiOutlinedInput-input{
   text-align: end !important;
}

.button_width .muiltr-4f8dkf-MuiButtonBase-root-MuiButton-root{
     min-width: 0;
     padding: 0;
}

.input_text .muiltr-1soyadh-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: end !important;
}

@media (min-width: 1500px) {
     .width_set{
          width: 180px;
     }
}

.slider .slick-track{
     margin-left: 0;
}

.date-color .muiltr-hgpioi-MuiSvgIcon-root{
     color: #0BA861;
     background-color: #006B5A29;
     padding: 2px;
     border-radius: 23px;
     /* width: 30px; */
}

.date-color .muiltr-hgpioi-MuiSvgIcon-root:hover {
     background-color: transparent !important;
 }


 .modal-container {
     position: relative;
   }
   
   .modal-overlay {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 1;
   }
   
   .modal-content {
     background-color: white;
     padding: 20px;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
   }
   
   .close-button {
     position: absolute;
     top: 0px;
     right: 10px;
   }

   .phone .react-tel-input .form-control{
     color: #959ca9
   }
   #appleid-signin img{
     margin: 0 auto;
   }

   .apexcharts-legend {
     justify-content: space-evenly !important;
   }
   .radio-group-container {
       overflow: hidden;
       position: relative;
       width: 100%;
       height: 100%; /* Adjust this if necessary */
  }

 .sliding-next {
    transition: transform 0.6s ease-in-out, opacity 0.4s ease-in-out;
    transform: translateX(-100%);
    opacity: 0;
 }

.sliding-previous {
    transition: transform 0.3s ease-in;
    transform: translateX(100%);
}

.slide-show {
    animation: slideIn 0.4s forwards, fadeIn 0.5s forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.quick-campaign .Mui-selected {
     color: #8B78DC;
}
.quick-chat .Mui-selected {
     color: #EBB32F;
}
.quick-social .Mui-selected {
     color: #219EBC;
}
.quick-review .Mui-selected {
     color: #2DB67C;
}


.quick-campaign .MuiTabs-indicator, .quick-campaign .Mui-checked+.MuiSwitch-track {
     background-color: #8B78DC;
}
.quick-chat .MuiTabs-indicator,.quick-chat .Mui-checked+.MuiSwitch-track{
     background-color: #EBB32F;
}
.quick-social .MuiTabs-indicator, .quick-social .Mui-checked+.MuiSwitch-track {
     background-color: #219EBC;
}
.quick-review .MuiTabs-indicator, .quick-review .Mui-checked+.MuiSwitch-track {
     background-color: #2DB67C;
}

.quick-chat .Mui-focused .MuiOutlinedInput-notchedOutline{
     border-color: #EBB32F; 
}
.quick-chat .MuiRadio-colorSecondary, .quick-chat .MuiRadio-colorSecondary.Mui-checked {
     color: #EBB32F;
}
.quick-chat .MuiRadio-colorSecondary:focus-visible {
     outline: 2px solid #EBB32F; 
}
.quick-chat .Mui-checked{
     color: #EBB32F;
}
.quick-campaign .Mui-focused .MuiOutlinedInput-notchedOutline{
     border-color: #8B78DC; 
}
.quick-campaign .Mui-checked{
     color: #8B78DC;
}
.quick-campaign .MuiRadio-colorSecondary, .quick-campaign .MuiRadio-colorSecondary.Mui-checked {
     color: #8B78DC; 
}
.quick-campaign .MuiRadio-colorSecondary:focus-visible {
     outline: 2px solid #8B78DC;
}

.quick-social .Mui-focused .MuiOutlinedInput-notchedOutline{
     border-color: #219EBC; 
}
.quick-social .MuiRadio-colorSecondary, .quick-social .MuiRadio-colorSecondary.Mui-checked {
     color: #219EBC; 
}
.quick-campaign .Mui-checked{
     color: #219EBC;
}
.quick-social .MuiRadio-colorSecondary:focus-visible {
     outline: 2px solid #219EBC;
}

.quick-social .Mui-checked{
     color: #219EBC;
}

.quick-review .Mui-focused .MuiOutlinedInput-notchedOutline{
     border-color: #2DB67C; 
}
.quick-review .MuiRadio-colorSecondary, .quick-review .MuiRadio-colorSecondary.Mui-checked {
     color: #2DB67C; 
}
.quick-review .MuiRadio-colorSecondary:focus-visible {
     outline: 2px solid #2DB67C;
}
.quick-campaign .Mui-checked{
     color: #8B78DC;
}

.inbox-height {
    height: calc(100% - 64px);
}

/* Main Container */
.payment-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #f0f9f6;
    border: 2px solid #0ba861;
    border-radius: 10px;
    padding: 84px 70px;
    margin: 50px auto;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Circle */
.success-icon {
    position: relative;
    width: 80px;
    height: 80px;
}

.success-icon .circle {
    width: 80px;
    height: 80px;
    background-color: #0ba861;
    border-radius: 50%;
    position: absolute;
    animation: popIn 0.6s ease-out;
}

.success-icon .checkmark {
    position: absolute;
    top: 3px;
    left: 24px;
    width: 36px;
    height: 72px;
    transform: rotate(45deg);
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    border-radius: 5px;
    animation: checkmarkDraw 0.4s ease-in-out 0.4s forwards;
}

/* Success Message */
.payment-success h2 {
    font-size: 2.8rem;
    color: #0ba861;
    margin: 0;
}

.payment-success p {
    font-size: 2rem;
    color: #555;
    margin: 0;
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes checkmarkDraw {
    0% {
        width: 0;
        height: 0;
    }
    100% {
        width: 30px;
        height: 62px;
    }
}

.arrow-bottom::after {
     content: "";
     position: absolute;
     bottom: -15px; /* Adjust for arrow size */
     left: 50%;
     transform: translateX(-50%);
     width: 0;
     height: 0;
     border-left: 10px solid transparent; /* Adjust width */
     border-right: 10px solid transparent; /* Adjust width */
     border-top: 15px solid #E8E8E8; /* Match background color of the popup */
     z-index: 1;

}

.payment-loader .binding {
    content : '';
    width : 60px;
    height : 4px;
    border : 2px solid #00c4bd;
    margin : 0 auto;
}

.payment-loader .pad {
    width: 75px;
    height: 49px;
    border-radius : 8px;
    border : 2px solid #00c4bd;
    padding : 6px;
    margin : 0 auto;
}

.payment-loader .chip {
    width : 12px;
    height: 8px;
    background: #00c4bd;
    border-radius: 3px;
    margin-top: 4px;
    margin-left: 3px;
}

.payment-loader .line {
    width : 52px;
    margin-top : 6px;
    margin-left : 3px;
    height : 4px;
    background: #00c4bd;
    border-radius: 100px;
    opacity : 0;
    -webkit-animation : writeline 3s infinite ease-in;
    -moz-animation : writeline 3s infinite ease-in;
    -o-animation : writeline 3s infinite ease-in;
    animation : writeline 3s infinite ease-in;
}

.payment-loader .line2 {
    width : 32px;
    margin-top : 6px;
    margin-left : 3px;
    height : 4px;
    background: #00c4bd;
    border-radius: 100px;
    opacity : 0;
    -webkit-animation : writeline2 3s infinite ease-in;
    -moz-animation : writeline2 3s infinite ease-in;
    -o-animation : writeline2 3s infinite ease-in;
    animation : writeline2 3s infinite ease-in;
}

.payment-loader .line:first-child {
    margin-top : 0;
}

.payment-loader .line.line1 {
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

.payment-loader .line.line2 {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.payment-loader .loader-text {
    text-align : center;
    margin-top : 20px;
    font-size : 16px;
    line-height: 16px;
    color : #5f6571;
    font-weight: bold;
}


@keyframes writeline {
    0% { width : 0px; opacity: 0; }
    33% { width : 52px; opacity : 1; }
    70% { opacity : 1; }
    100% {opacity : 0; }
}

@keyframes writeline2 {
    0% { width : 0px; opacity: 0; }
    33% { width : 32px; opacity : 1; }
    70% { opacity : 1; }
    100% {opacity : 0; }
}
.venn-center svg{
     margin: 0 auto;
}